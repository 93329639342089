<template>
  <van-nav-bar :title="type === 'recharge' ? $t('title.buyCoinBankcard') : $t('title.withdrawalBankcard')" left-arrow @click-left="goBack" :border="false" />
  <div class="bank-form">
    <div class="form-wrap">
      <div class="form-title">{{$t('field.bankName')}}</div>
      <div class="field-wrap" v-if="$AppData.config.bankcardModel === 2">
        <van-field
          :model-value="bankNameList[bankIndex]"
          class="form-field with-suffix"
          @click="showBankPicker = true"
          readonly
        />
        <div class="suffix-wrap" @click="showBankPicker = true">
          <img class="triangle" src="/images/triangle.png">
        </div>
      </div>
      <van-field
        v-else
        v-model="bankName"
        class="form-field"
        size="large"
        maxlength="32"
        clearable
        :formatter="formatter"
        format-trigger="onBlur"
        :placeholder="$t('placeholder.default')"
      />
      <div class="form-title">{{$t('field.bankCardNumber')}}</div>
      <van-field
        v-model="account"
        class="form-field"
        size="large"
        maxlength="32"
        clearable
        :formatter="formatter"
        format-trigger="onBlur"
        :placeholder="$t('placeholder.default')"
      />
      <div class="form-title">{{$t('field.cardholder')}}</div>
      <van-field
        v-model="cardholder"
        class="form-field"
        size="large"
        maxlength="64"
        clearable
        :formatter="formatter"
        format-trigger="onBlur"
        :placeholder="$t('placeholder.default')"
      />
      <!-- <template v-if="bankList[bankIndex] && bankList[bankIndex].code === 'IFSC -->
      <template v-if="$AppData.config.bankcardModel === 1">
        <div class="form-title">IFSC</div>
        <van-field
          v-model="ifsc"
          class="form-field"
          size="large"
          maxlength="32"
          clearable
          :formatter="formatter"
          format-trigger="onBlur"
          :placeholder="$t('placeholder.default')"
        />
      </template>
      <template v-if="type != 'recharge'">
        <div class="form-title">{{$t('field.verifyCode')}}</div>
        <div class="field-wrap">
          <van-field
            v-model="verifyCode"
            class="form-field with-prefix"
            size="large"
            type="digit"
            maxlength="6"
            clearable
            :placeholder="$t('placeholder.default')"
          />
          <van-button v-if="coldDownTime === 0" type="primary" class="sms-btn" @click="sendVerifyCode">{{$t('button.smsBtn')}}</van-button>
          <van-button v-else type="primary" class="sms-btn" :disabled="true">{{$t('button.smsColdDown')}} {{coldDownTime}}{{$t('button.second')}}</van-button>
        </div>
      </template>
    </div>
    <van-button
      size="large"
      type="primary"
      block
      @click="handleUpdateAccount"
      :disabled="confirmDisabled"
    >{{$t('button.submit')}}</van-button>
  </div>
  <van-popup v-model:show="showBankPicker" position="bottom">
    <van-picker
      :columns="bankNameList"
      @confirm="onBankConfirm"
      @cancel="showBankPicker = false"
    />
  </van-popup>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { addRechargeBankcard, addWithdrawalBankcard, sendWithdrawalBankcardSms, queryBankList } from "@/api";
  
export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const bankList = ref([]);
    const bankNameList = ref([]);
    const bankIndex = ref(0);
    const showBankPicker = ref(false);
    const onBankConfirm = (value, index) => {
      bankIndex.value = index;
      showBankPicker.value = false;
    };
    
    // 过滤输入的前后空格
    const formatter = (value) => value.trim();
    
    onMounted(() => {
      queryBankList().then(res => {
        bankList.value = res.data || [];
        bankNameList.value = (res.data || []).map(item => item.value);
      })
    })
    return {
      goBack, bankList, bankNameList, bankIndex, showBankPicker, onBankConfirm, formatter
    };
  },
  
  data() {
    return {
      type: this.$route.params.type || 'recharge',
      id: undefined,
      bankName: '',
      cardholder: '',
      account: '',
      ifsc: '',
      verifyCode: '',
      coldDownTime: 0,
      coldDownInterval: null,
    };
  },
  
  computed: {
    confirmDisabled() {
      let result;
      if (this.type === 'recharge') {
        result = !this.cardholder || !this.account;
      } else {
        result = !this.cardholder || !this.account || this.verifyCode.length != 6;
      }
      if (this.$AppData.config.bankcardModel === 1) {
        return result || !this.ifsc;
      } else {
        return result;
      }
    },
  },
  
  // unmounted() {
  //   localStorage.removeItem('currentBank');
  // },

  methods: {
    sendVerifyCode() {
      sendWithdrawalBankcardSms().then(() => {
        this.$toast({ type: 'success', message: i18n.global.t('notify.sentSuccess') });
        this.coldDownTime = 60;
        this.coldDown();
      });
    },
    handleUpdateAccount() {
      const accoutReg = /^[0-9]*$/;
      if (!accoutReg.test(this.account)) {
        this.$toast({ type: 'danger', message: i18n.global.t('notify.error.accountFormat') });
        return false;
      }
      let bankCode = this.bankName;
      if(this.$AppData.config.bankcardModel === 2) {
        bankCode = this.bankList[this.bankIndex].code;
      }
      const postData = {
        // id: this.id,
        bankCode,
        bankCardholder: this.cardholder,
        bankCardNo: this.account,
        ifsc: this.$AppData.config.bankcardModel === 1 ? this.ifsc : undefined,
        verifyCode: this.verifyCode,
      };
      const doPost = this.type === 'recharge' ? addRechargeBankcard : addWithdrawalBankcard;
      doPost(postData).then(res => {
        this.$toast({ type: 'success', message: i18n.global.t('notify.operateSuccess') });
        this.$router.back();
      });
    },
    coldDown() {
      if (this.coldDownInterval) {
        clearInterval(this.coldDownInterval);
      }
      this.coldDownInterval = setInterval(() => {
        this.coldDownTime = this.coldDownTime - 1;
        if (this.coldDownTime ===0) {
          clearInterval(this.coldDownInterval);
        }
      }, 1000)
    },
  },
};
</script>

<style lang="less">
.bank-form {
  padding: 12px;
  .form-wrap {
    padding: 0 12px 16px;
    margin-bottom: 12px;
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    .form-title {
      margin: 16px 0;
      font-size: 14px;
      color: #28293D;
      font-weight: 400;
    }
    .field-wrap {
      position: relative;
      .suffix-wrap {
        position: absolute;
        top: 0;
        right: 12px;
        width: 110px;
        line-height: 46px;
        text-align: right;
        font-weight: 400;
        color: #6F6F93;
        span {
          font-size: 16px;
          vertical-align: middle;
        }
        .triangle {
          width: 8px;
          margin-left: 12px; 
          vertical-align: middle;
        }
      }
    }
    .form-field {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      background: #F9FAFB;
      border-radius: 4px;
      &.with-suffix {
        padding-right: 110px;
      }
    }
    .sms-btn {
      position: absolute;
      right: 4px;
      top: 7px;
      height: 36px;
    }
    .field-extra {
      padding: 8px 0;
      font-size: 10px;
      color: #6F6F93;
      line-height: 12px;
      font-weight: 400;
      .highlight {
        color: var(--van-primary-color);
      }
    }
  }
}
</style>
